import { DateHelper, eventTimeString, ResolvedEventSummary, timeString } from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { localizedFormat } from "../../utils/date";
import { TranslateMicroText } from "../../utils/hooks/useTranslation";
import { getEventScheduleDetails } from "./utils";

export const eventScheduleToString = (
  event: Pick<ResolvedEventSummary, "eventSchedule">,
  lang: FrontendLocale,
  t: TranslateMicroText
): string => {
  const { isMultiDayEvent, allDay, startsAt, endsAt } = getEventScheduleDetails(event, lang);

  if (isMultiDayEvent) {
    if (allDay) {
      return `${localizedFormat(startsAt, "do MMM", lang)} - ${localizedFormat(endsAt, "do MMM", lang)}`;
    }
    const endsToday = new DateHelper(endsAt).isSameDay(new Date());
    if (endsToday) {
      return t("Varer til {time} i dag", { time: timeString(endsAt, lang) });
    }
    const hasStarted = new DateHelper(startsAt).isBeforeNow();
    if (hasStarted) {
      return t("Hele dagen. Varer til {time}", { time: localizedFormat(endsAt, "do MMM p", lang) });
    }
    return `${timeString(startsAt, lang)} - ${localizedFormat(endsAt, "do MMM p", lang)}`;
  }

  if (allDay) {
    return t("Hele dagen");
  }

  return eventTimeString(lang, event);
};
