import {
  Box,
  BoxProps,
  ChakraProps,
  Container,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Icon,
  LinkBox,
  Stack,
  Text,
  VisuallyHidden,
} from "@biblioteksentralen/react";
import { sortArrayInGroups } from "@biblioteksentralen/utils";
import {
  DateHelper,
  getEventHeadings,
  getPath,
  isRepeatedEventOccurence,
  ResolvedEventSummary,
  SIMPLE_ISO_DATE_FORMAT,
} from "@libry-content/common";
import { motion } from "framer-motion";
import { useEffect, useId, useRef } from "react";
import { Clock } from "react-feather";
import { localizedFormat } from "../../utils/date";
import { useTranslation } from "../../utils/hooks/useTranslation";
import useRerenderInterval from "../../utils/useRerenderInterval";
import { useSitePalette } from "../../utils/useSitePalette";
import { Edit } from "../editInSanity/EditInSanity";
import InternalLinkOverlay from "../InternalLinkOverlay";
import { EventBadges } from "./EventAudienceAndTypeBadges";
import { EventLocation } from "./EventLocation";
import { eventScheduleToString } from "./eventScheduleToString";
import { EventStatusTitlePrefix } from "./EventStatus";
import { LocationIcon } from "./LocationIcon";
import { useCurrentEventStatus } from "./useCurrentEventStatus";
import { getEventScheduleDetails } from "./utils";

type Props = { events: ResolvedEventSummary[] };

export const EventsList = ({ events, ...chakraProps }: Props & ChakraProps) => {
  const { lang } = useTranslation();

  const groupedByDate = sortArrayInGroups(events, (event) => {
    const { startDate, endsAt, startsAt } = getEventScheduleDetails(event, lang);
    if (!startDate) return "Ukjent dato";

    const isStarted = new DateHelper(startsAt).isBeforeNow();
    const isFinished = new DateHelper(endsAt).isBeforeNow();
    // Viser arrangementet på dagens dato hvis det allerede har startet og ikke er over
    if (!isFinished && isStarted) return localizedFormat(new Date().toISOString(), SIMPLE_ISO_DATE_FORMAT, lang);

    return startDate;
  });

  return (
    <Container maxWidth="container.md" padding="0" {...chakraProps}>
      <Stack as="ol">
        {groupedByDate.map((group) => (
          <DateGroup
            key={group.label}
            date={group.label}
            events={group.items}
            isLastGroupInList={groupedByDate[groupedByDate.length - 1] === group}
          />
        ))}
      </Stack>
    </Container>
  );
};

const DateGroup = ({
  date,
  events,
  isLastGroupInList,
}: {
  date: string;
  events: ResolvedEventSummary[];
  isLastGroupInList: boolean;
}) => {
  const id = useId();
  const palette = useSitePalette();

  return (
    <Flex as="li" gap=".75rem" aria-labelledby={id}>
      <Dato as="h2" dato={date} trail={isLastGroupInList ? "short" : "long"} id={id} />
      <Stack
        marginTop="-.5rem" // For å aligne med dato-badge
        flex="1"
        as="ol"
      >
        {events.map((event) => {
          const lastItem = events[events.length - 1] === event;
          const showDivider = !lastItem || !isLastGroupInList;
          return (
            <Box key={`${event._id}-${event.eventSchedule?.startsAt}`} as="li" listStyleType="none">
              <EventPreview event={event} />
              {showDivider && <Box borderBottom={`solid 1px ${palette.colors.card.css.background}`} />}
            </Box>
          );
        })}
      </Stack>
    </Flex>
  );
};

type EventPreviewProps = {
  event: ResolvedEventSummary;
  autoFocus?: boolean;
} & FlexProps;

const EventPreview = ({ event, autoFocus, ...chakraProps }: EventPreviewProps) => {
  const { ts } = useTranslation();
  const headerId = useId();

  return (
    <LinkBox _hover={{ background: "rgba(0,0,0,0.035)" }} aria-labelledby={headerId} {...chakraProps}>
      <motion.div layoutId={event._id}>
        <Stack spacing=".1rem" padding={{ base: ".5rem 0 .75rem .5rem", sm: ".5rem 1rem .75rem" }}>
          <Title event={event} autoFocus={autoFocus} headerId={headerId} />
          <Text fontSize="sm">{ts(event.teaser)}</Text>
          <EventTimeAndPlace event={event} />
          <EventBadges marginTop=".2rem" fontSize="2xs" event={event} />
        </Stack>
      </motion.div>
    </LinkBox>
  );
};

export const EventTimeAndPlace = ({ event }: { event: ResolvedEventSummary }) => {
  const { t, lang } = useTranslation();

  return (
    <>
      <Info ariaLabel={t("tidspunkt")} icon={Clock}>
        <Text fontSize="sm">{eventScheduleToString(event, lang, t)}</Text>
        <LiveStatus event={event} />
      </Info>
      <Info ariaLabel={t("sted")} icon={LocationIcon}>
        <EventLocation fontSize="sm" event={event} />
      </Info>
    </>
  );
};

const Info = (props: { icon: typeof Clock; ariaLabel: string; children: React.ReactNode }) => (
  <HStack>
    <Icon aria-label={props.ariaLabel} as={props.icon} flexShrink={0} />
    <Flex gap="0 .5rem" flexWrap="wrap" fontWeight={600}>
      {props.children}
    </Flex>
  </HStack>
);

const Title = ({
  event,
  headerId,
  autoFocus,
  ...chakraProps
}: {
  event: ResolvedEventSummary;
  headerId: string;
  autoFocus?: boolean;
} & FlexProps) => {
  const { ts } = useTranslation();

  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    autoFocus && ref.current?.focus();
  }, [autoFocus]);

  const { heading, subheading } = getEventHeadings(event);

  return (
    <Flex flexDirection="column-reverse" as="header" {...chakraProps}>
      <Heading as="h3" size="md" id={headerId} _hover={{ textDecoration: "underline" }}>
        <InternalLinkOverlay href={getPath(event)} ref={ref}>
          <Box>
            <EventStatusTitlePrefix event={event} />
            {ts(heading)}
          </Box>
        </InternalLinkOverlay>
      </Heading>
      {subheading && (
        <Text fontSize="sm" fontWeight={600}>
          {ts(subheading)}
        </Text>
      )}
      <Edit
        doc={{
          _id: isRepeatedEventOccurence(event) ? event.series.id : event._id,
          _type: "event",
        }}
      />
    </Flex>
  );
};

const Dato = ({ dato, trail, ...chakraProps }: { dato: string; trail: "short" | "long" } & BoxProps) => {
  const { lang } = useTranslation();
  const palette = useSitePalette().colors.card;

  return (
    <Flex {...chakraProps}>
      <VisuallyHidden>{localizedFormat(dato, "EEEE, PPP", lang)}</VisuallyHidden>

      <Flex
        aria-hidden={true} // Screen readers get a better representation of the date from the visually hidden text
        direction="column"
        alignItems="center"
        fontSize={{ base: ".85rem", sm: "1rem" }}
        minWidth={{ base: "3em", md: "4em" }}
        position="relative"
      >
        <Box
          position="absolute"
          height={trail === "long" ? `calc(100% + 3rem)` : "100%"} // 3rem is the space between date-groups
          borderLeft={`solid .2em ${palette.css.background}`}
          borderBottomRadius={trail === "short" ? "md" : undefined}
        />
        <Flex
          as={motion.div}
          layoutId={dato}
          position="sticky"
          top=".75rem"
          direction="column"
          alignItems="center"
          justifyContent="center"
          borderRadius="md"
          aria-hidden={true}
          lineHeight={1}
          aspectRatio={1}
          padding=".5em"
          {...palette.css}
        >
          <Text textTransform="uppercase" fontSize=".7em">
            {localizedFormat(dato, "EE", lang)}
          </Text>
          <Text fontSize="1.3em" fontWeight="600">
            {localizedFormat(dato, "d", lang)}
          </Text>
          <Text fontWeight="600" textTransform="uppercase" fontSize=".7em">
            {localizedFormat(dato, "MMM", lang).replace(".", "")}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const LiveStatus = ({ event, ...chakraProps }: { event: ResolvedEventSummary } & ChakraProps) => {
  const liveStatus = useCurrentEventStatus(event);
  useRerenderInterval();

  return (
    <Box color={liveStatus?.color} fontWeight={600} fontSize="sm" {...chakraProps}>
      {liveStatus?.text}
    </Box>
  );
};
